/*　include
------------------------------------------------------- */

import "core-js/stable";
import "regenerator-runtime/runtime";
import './scss/style.scss';

/*
------------------------------------------------------- */

document.addEventListener('DOMContentLoaded', (event) => {
function lazyLoadVideo () {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.pump.tokyo/wp-content/themes/pump/video/bg_1.mp4', true);
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        var blob = this.response;
        var src = (window.webkitURL || window.URL).createObjectURL(blob);
        $('#mvVideo').append('<source type="video/mp4" src="' + src + '">');
      }
    };
    xhr.send();
  }
lazyLoadVideo();
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    document.getElementById("mvVideo").play();
  }, 2300);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $("#logo").addClass("fadeIn");
    $("#btn01").addClass("fadeIn");
  }, 2750);
});

function textAnimation() {
  let title1 = new TimelineMax();
  title1.staggerFromTo(".el_splash_inner_logo_ttl span", 0.6, 
  {ease: Back.easeOut.config(1.8), opacity: 0, left: -30},
  {ease: Back.easeOut.config(1.8), opacity: 1, left: 0}, 0.1);
}

$(document).ready(function(){
setTimeout(function() {
	textAnimation();
},700);
});

$(document).ready(function(){
setTimeout(function() {
$(".el_splash_inner_logo_ttl").fadeOut();
},2000);
});

document.addEventListener('DOMContentLoaded', (event) => {
let splashWrapA = CSSRulePlugin.getRule(".el_splash");
let splashAfter = CSSRulePlugin.getRule(".el_splash:after");
let splashTlA = new TimelineMax({
  delay: 0,
})
.to(splashAfter,0.8,{ease:Power3.easeInOut,cssRule:{scaleX:0}}, 'gate')
.to(splashAfter,1,{cssRule:{display:"none"}}, 'gate');
});

document.addEventListener('DOMContentLoaded', (event) => {
let splashWrap = CSSRulePlugin.getRule(".el_splash");
let splashBefore = CSSRulePlugin.getRule(".el_splash:before");
let openingTtl = CSSRulePlugin.getRule(".el_splash_inner_logo_ttl");
let splashTl = new TimelineMax({
	delay: 2,
})
.to(splashBefore,1,{ease:Power3.easeInOut,cssRule:{scaleX:0}}, 'gate')
.to(splashWrap,1,{cssRule:{display:"none"}}, 'gate');
});

$(function() {
$('.js_header_btn').hover(function() {
$(this).css('transform', 'scale(0.9)')
}, function() {
$(this).css('transform', 'scale(1.0)')
});
});

if (matchMedia('(max-width: 768px)').matches) {
setTimeout(function() {
  ityped.init('#js_ttl_type', {
    strings: ['We Hope \nOur Creation \nMakes \nWorld Better'],
    typeSpeed: 120,
    startDelay: 150,
    loop: false,
    showCursor: false,
  });
}, 2750);
} else {
setTimeout(function() {
  ityped.init('#js_ttl_type', {
    strings: ['We Hope Our Creation \nMakes World Better'],
    typeSpeed: 120,
    startDelay: 150,
    loop: false,
    showCursor: false,
  });
}, 2750);
}

setTimeout(function() {
  $('#js_ttl_type').addClass('done');
}, 11000);

$(document).ready(function() {
let toggleButton = document.querySelector('.js_header_btn');
toggleButton.addEventListener('click',function(){
    let target = this.nextElementSibling;
    const result = target.classList.contains('open');
    if (result) {
        target.classList.remove('open');
        gsap.to(target,{
            width: '0vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }else{
        target.classList.add('open');
        gsap.to(target,{
            width:'100vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }
})
});

$(document).ready(function() {
let closeArea = document.querySelector('.un_closeArea');
closeArea.addEventListener('click',function(){
    let target = document.querySelector('.ly_header_inner');
    const result = target.classList.contains('open');
    if (result) {
        target.classList.remove('open');
        gsap.to(target,{
            width: '0vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }else{
        target.classList.add('open');
        gsap.to(target,{
            width:'100vw',
            duration:0.5,
            ease: "power2.inOut"
        });
    }
})
});

$.fn.clickToggle = function (a, b) {
  return this.each(function () {
    var clicked = false;
    $(this).on('click', function () {
      clicked = !clicked;
      if (clicked) {
        return a.apply(this, arguments);
      }
      return b.apply(this, arguments);
    });
  });
};

const mediaQuery = window.matchMedia('(max-width: 768px)');
handle(mediaQuery);
mediaQuery.addListener(handle);
function handle(mm) {
  if (mm.matches) {
$(function(){
  $('.js_header_btn').on('click', function() {
    $(this).toggleClass('active');
    return false;
  });
  $(".un_closeArea").on("click", function() {
    $(".js_header_btn").removeClass("active");
  });
$("#btn01").on("click", function() {
  setTimeout(function() {
  $(".ly_header_inner_left").toggleClass("sp-open");
  $(".ly_header_inner_right").toggleClass("sp-open");
  $("#logo.sp").toggleClass("sp-open");
  $(".ly_header_inner_sns").addClass("sp-open");
  $(".ly_header_inner_sns").removeClass("sp-close");
  },550);
});
$("#btn01").on("click", function() {
  $(".ly_header_inner_sns").removeClass("sp-open");
  $(".ly_header_inner_sns").addClass("sp-close");
});
$(".un_closeArea").on("click", function() {
  $(".ly_header_inner_left").removeClass("sp-open");
  $(".ly_header_inner_right").removeClass("sp-open");
  $(".ly_header_inner_sns").removeClass("sp-open");
  $("#logo.sp").removeClass("sp-open");
});
});
  } else {
$(".js_header_btn").on("click", function() {
  setTimeout(function() {
    $(".ly_header_inner_left").removeClass("hidden");
    $(".ly_header_inner_sns").removeClass("hidden");
    $(".ly_header_inner_right").removeClass("hidden");
  },550);
  setTimeout(function() {
    $(".ly_header_inner_right").addClass("visible");
  },350);
  setTimeout(function() {
    $(".ly_header_inner_left").addClass("visible");
    $(".ly_header_inner_sns").addClass("visible");
  },900);
});
$(".un_closeArea").on("click", function() {
  $(".ly_header_inner_left").removeClass("visible");
  $(".ly_header_inner_right").removeClass("visible");
  $(".ly_header_inner_left").addClass("hidden");
  $(".ly_header_inner_sns").addClass("hidden");
  $(".ly_header_inner_sns").removeClass("visible");
});
  }
}

$(function() {
  $("#btn01,header.ly_header .ly_header_inner .un_closeArea").on("click", function() {
    $('.ly_header').toggleClass('active-sp')
  });
});
